export var TokenType;
(function (TokenType) {
  TokenType["id"] = "id";
  TokenType["access"] = "access";
  TokenType["refresh"] = "refresh";
})(TokenType || (TokenType = {}));
export var AuthConnectErrors;
(function (AuthConnectErrors) {
  AuthConnectErrors["EmptyConfigError"] = "Auth Connect Config has not been set. Please call setup(...) first.";
})(AuthConnectErrors || (AuthConnectErrors = {}));
